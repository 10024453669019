import logo from './Pimm_signature_white.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="company-logo" alt="Pimm" />
        <h3>
          Pimm Corporation
        </h3>
      </header>
    </div>
  );
}

export default App;
 